class LandingPriceList {
  constructor(country) {
    this.country = country || "ru";
    this.city_mobile = true;
    this.mobile = false;
    this.number8800 = false;
    this.current_price = 10;
    this.options_state =  [
      {
        text: 'Бесплатная<br>переадресация',
        data: "free-redirection",
        enabled: true,
        active: true
      },{
        key: 'call_record',
        text: 'Запись<br>разговоров',
        data: "record",
        enabled: false,
        active: true,
        price: 10
      },{
        text: 'Подмена<br>и аналитика',
        data: "replacement-analitics",
        enabled: true,
        active: true
      },{
        key: 'vats',
        text: 'Виртуальная<br>АТС',
        data: "vats",
        enabled: false,
        active: true,
        price: 5
      }
    ];

    this.price_count();
    this.price_list();
  }

  counter_price_day() {
    $('#current_price_day').each(function () {
      $(this).prop(this.current_price, 0).animate({
          Counter: $(this).text()
      }, {
          duration: 600,
          easing: 'swing',
          step: function (now) {
              $(this).text(now.toFixed(0));
          }
      });
    });
  }

  counter_price_mounth() {
    $('#current_price_month').each(function () {
      $(this).prop(this.current_price_month, 0).animate({
          Counter: $(this).text()
      }, {
          duration: 600,
          easing: 'swing',
          step: function (now) {
              $(this).text(now.toFixed(0));
          }
      });
    });
  }

  price_count() {
    let price_text = `<div id="current_price_day" data-value="${this.current_price}">${this.current_price.toFixed(0)}</div><span class="ruble-icon">₽</span> `;
    return price_text;
  }

  price_count_mounth() {
    let price_text_mounth = `<p><span id="current_price_month">${(this.current_price * 30).toFixed(0)}</span> <span class='ruble-icon'>₽</span> в месяц</p>`;
    return price_text_mounth;
  }

  choice_country(select_country) {
    if (this.country == $(select_country).attr("data-value")) return;

    this.country = $(select_country).attr("data-value");
    if(this.country == "kz"){
      this.current_price += 10;
      this.select_city_mobile();
    }else{
      this.current_price -= 10;
    }
    return this.draw(), this.counter_price_day(), this.counter_price_mounth();
  }

  select_number_option() {
    let select_number_text = `Один <bold class="number_select ${this.city_mobile ? "disabled" : ""}"
                                onclick="LandingPriceList.select_city_mobile(); LandingPriceList.draw_select_options();">городской</bold><span class=${this.country == "kz" ? "hidden_mobile_n8800" : ""}>, 
                              <bold class="number_select ${this.mobile ? "disabled" : ""}" 
                                onclick="LandingPriceList.select_mobile(); LandingPriceList.draw_select_options();">мобильный</bold> 
                              или&nbsp;<bold class="number_select ${this.number8800 ? "disabled" : ""}" 
                                onclick="LandingPriceList.select_number8800(); LandingPriceList.draw_select_options();">8800</bold></span>
                              номер<br> со следующими опциями:`;
    return select_number_text;
  }

  select_city_mobile() {
    let select_option;
    if(this.number8800 === true) this.current_price -= 10;
    this.city_mobile = true;
    this.mobile = false;
    this.number8800 = false;  

    this.draw();
    this.counter_price_day();
    this.counter_price_mounth();

    $('#border-shell').attr('data-number','city-mobile');
    $('[data-name-option="n8800"]').attr('data-name-option',"free-redirection");
    $('[data-name-option="free-redirection"] .option-text').html('Бесплатная<br>переадресация');
    $('.price-desc').css('display', 'block');
  }

  select_mobile() {
    let select_option;
    if(this.number8800 === true) this.current_price -= 10;
    this.city_mobile = false;
    this.mobile = true;
    this.number8800 = false;

    this.draw();
    this.counter_price_day();
    this.counter_price_mounth();

    $('#border-shell').attr('data-number','mobile');
    $('[data-name-option="n8800"]').attr('data-name-option',"free-redirection");
    $('[data-name-option="free-redirection"] .option-text').html('Бесплатная<br>переадресация');
    $('.price-desc').css('display', 'block');
  }

  select_number8800() {
    let select_option;
    this.city_mobile = false;
    this.mobile = false;
    this.number8800 = true;
    this.current_price += 10;

    this.draw();
    this.counter_price_day();
    this.counter_price_mounth();

    $('#border-shell').attr('data-number','8800');
    $('[data-name-option="free-redirection"]').attr('data-name-option',"n8800");
    $('[data-name-option="n8800"] .option-text').html('<b>5<span class="ruble-icon">₽</span></b> за минуту входящего звонка');
    $('.price-desc').css('display', 'none');
  }

  price_list() {
    let option_gudok = '', select_option;
    for(let option in this.options_state) {
      select_option = this.options_state[option];
      option_gudok +=
      `<div class="option-gudok" data-name-option="${select_option.data}" id="price-${option}">
         ${this.template_option(select_option)}
      </div>`;
    }
    return option_gudok;
  }

  template_option(option) {
    return `<div class="container-svg hover-block-help ${option.enabled ? "active"  : "unactive" } ${option.active === false ? "disabled-main" :  ""}" 
              ${`style="cursor: pointer"`}
              ${`onclick="LandingPriceList.calculate('${option.key}')"`}
            ">
            <div class="option-svg">
              <div class="${option.enabled ? "option-on" : "option-off"}"></div>
              ${typeof option.price !== "undefined" ? `<div class="option-price">+${option.price} <span class="ruble-icon">i</span></div>` : ""}
            </div>
            <div class="option-text">${option.text}</div>
          </div> 
          ${option.key && this.form_element_name ? `<input type='hidden' value='${option.key}' name='${this.form_element_name}' ${option.enabled ? '' : 'disabled'}  /> ` : ""}`;
  }

  calculate(option_name) {
    for(let option in this.options_state) {
      if(option_name === this.options_state[option].key) {
        let enabled = this.options_state[option].enabled =
        !this.options_state[option].enabled;
        if(enabled) {
          this.current_price += this.options_state[option].price, this.draw_click(option);
        } else {
          this.current_price -= this.options_state[option].price, this.draw_click(option);
        }
        this.counter_price_day();
        this.counter_price_mounth();

        // T0D0 на лендах это вызовет TypeError: is not a function,
        // но на общую работоспособность это не повлияет.
        // После рефакторинга необходимость в этом костыле отпадёт.
        window.update_button_text();
      }
    }
  }

  draw() {
    let price_text = document.getElementById('value-text'),
        price_text_mounth = document.getElementById('price-mounth'),
        options_gudok = document.getElementById('options-gudok');

    price_text.innerHTML = this.price_count();
    if (options_gudok) options_gudok.innerHTML = this.price_list();
    if (price_text_mounth) {
      this.draw_select_options();
      price_text_mounth.innerHTML = this.price_count_mounth();
    }
  }

  draw_select_options() {
    let select_options_mpage = document.getElementById('select-options-mpage');
    select_options_mpage.innerHTML = this.select_number_option();
  }

  draw_click(option) {
    let price_text = document.getElementById('value-text'),
        price_text_mounth = document.getElementById('price-mounth'),
        option_gudok = document.getElementById(`price-${option}`);

    price_text.innerHTML = this.price_count();
    if (option && option_gudok) option_gudok.innerHTML = this.template_option(this.options_state[option]);
    if (price_text_mounth) {
      price_text_mounth.innerHTML = this.price_count_mounth();
    }
  }

  load(form_element_name) {
    this.form_element_name = form_element_name;
    this.draw();
  }

}
export default LandingPriceList;